<div class="home-wrap">

    <div class="home-wrap-logo">
        <img src="../../assets/spectiv-by-dw-white.svg" alt="Spectiv" />
    </div>

    <div class="home-wrap-item">
        <p class="instructions title">Welcome to the Spectiv temporary email system.</p>
        <p class="instructions">Enter an address to access the inbox.</p>
    <p class="input-wrap">
        <input type="text" placeholder="Enter Address" [(ngModel)]="inbox" (keyup)="scrubEmail($event)"/>@spectiv.email
    </p>
    <button [routerLink]="['inbox/' + inbox]" [disabled]="inbox.length == 0">Go To Inbox</button>

</div>
</div>